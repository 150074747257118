<template>
    <div class="table-container">
        <b-table :fields="fileFields"
                 :items="files"
                 responsive="true"
                 bordered
                 head-variant="light"
                 sticky-header="true"
                 no-border-collapse>
            <template #thead-top="data">
                <b-tr class="text-center">
                    <b-th colspan="4">{{ getCardTitle('sector_1', 'attached_docs') }}</b-th>
                </b-tr>
            </template>
            <template #cell(type)="data">
                <div></div>
            </template>
            <template #cell(name)="data">
                <b-link target="_blank" href="#" @click="downloadFile(data.item.name)">{{ data.value }}</b-link>
            </template>
            <template #cell(text)="data">
                <b-form-textarea
                    id="textarea"
                    v-model="data.item.text"
                    :placeholder="getSmsTitle('add_description_doc')"
                    rows="5"
                    @blur="getFiles"
                    @input="getFiles"
                    :disabled="reading">
                </b-form-textarea>
            </template>
            <template #cell(more)="data">
                <b-button :disabled="reading" @click="delFile(data.index, data.item.name)">
                    <i class="icon icon-close"></i>
                </b-button>
            </template>
            <template #bottom-row="data">
                <td></td>
                <td>
                    <b-form-file size="sm"
                                 id="file"
                                 ref="file-input"
                                 accept="*.*"
                                 v-model="file"
                                 :disabled="reading"
                                 @input="uploadFile">
                    </b-form-file>
                </td>
                <td>
                    <a href="#" class="nav-link download-all" @click="downloadAllFile(false)">
                        {{ getBipTitle('btn.download_all') }}
                        <span>({{(files && files.length > 0) ? files.length : 0}})</span></a>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Ax } from '@/utils';
import axios from "axios";
import {getBipTitle, getCardTitle, getSmsTitle, makeToast} from './bip-types';

export default {
    name: 'FileLoad',
    props: {
        reading: null,
        year: null,
        code: null,
        files: []
    },
    data() {
        return {
            fileFields: [
                {
                    key: 'type',
                    label: ''
                },
                {
                    key: 'name',
                    label: ''
                },
                {
                    key: 'text',
                    label: ''
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            file: null,
            text: ''
        }
    },

    methods: {
        async delFile(index, file_name) {
            this.$bvModal.msgBoxConfirm(
                getSmsTitle(this, 'delete_file'),
                {
                    title: getSmsTitle(this, 'confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.delete'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        this.deleteFile(index, file_name);
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), error.toString());
                });

        }, // удаление файла

        async deleteFile(index, file_name) {
            try {
                Ax(
                    {
                        url: '/api-py/file-delete/' + this.year + '/' + this.code + '/' + file_name + '/',
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        data: {},
                        responseType: 'json'
                    },
                    () => {
                        this.files.splice(index, 1);
                        this.getFiles();
                        makeToast(this, 'success', getSmsTitle(this, 'message'), getSmsTitle(this, 'file_deleted'));
                    },
                    (error) => {
                        makeToast(this, 'danger', getSmsTitle(this, 'warning'), error.toString());
                    }
                );
            } catch {
                makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), '');
            }
        }, // удаляет файл с хранилища

        downloadFile(filename) {
            Ax(
                {
                    url: '/api-py/file-download/' + this.year + '/' + this.code + '/' + filename,
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_download'), error.toString());
                }
            );
        }, // скачать файл

        downloadAllFile(filesArr = false) {
            const that = this;
            const allFileNames = [];
            const files = filesArr || that.files;
            files.forEach(file => {
                allFileNames.push(file.name);
            });

            if (allFileNames && allFileNames.length > 0) {
                Ax(
                    {
                        url: '/api-py/files-download/' + this.year + '/' + this.code,
                        method: 'POST',
                        data: allFileNames,
                        responseType: 'blob'
                    },
                    async (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.code + '_' + this.year + '.zip');// or any other extension
                        document.body.appendChild(link);
                        link.click();

                        await axios.post('/api-py/delete-zip-by-path/' + this.year + '/' + this.code); // запрос чтобы удалить сгенерированный архив
                    },
                    async (error) => {
                        makeToast(this, 'danger', getSmsTitle(this, 'error_download'), error.toString());
                        await axios.post('/api-py/delete-zip-by-path/' + this.year + '/' + this.code); // запрос чтобы удалить сгенерированный архив
                    }
                );
            }
        }, // скачать архив всех прикрепленных файлов

        getFiles() {
            const data = this.files;
            this.$emit('getFiles', data);
        },

        async uploadFile () {
            if (this.code === 'BIP-00000' && this.file !== null) {
                makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                    getSmsTitle(this, 'first_save_BIP'));
                this.$refs['file-input'].reset();
                return;
            }
            const that = this;
            const newItem = {};
            const promise = new Promise(function (resolve, reject) {
                if (that.file == null) {
                    return;
                }

                const newName = that.file.name.replace(" ", "_");
                const newFile = new File([that.file], newName, {type: that.file.type});
                const formData = new FormData();
                formData.append('file', newFile);
                formData.append('filetype', newFile.type);
                formData.append('filename', newFile.name);
                formData.append('year', that.year);
                formData.append('code', that.code);
                that.$set(newItem, 'type', newFile.type);
                that.$set(newItem, 'name', newFile.name);
                that.$set(newItem, 'text', '');
                Ax(
                    {
                        url: '/api-py/file-load/' + that.year + '/' + that.code + '/',
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData,
                        responseType: 'json'
                    },
                    (data) => {
                        resolve({f: data['existed'], newItem: newItem});
                    },
                    () => {
                        resolve({f: 'error', newItem: newItem});
                    }
                );
            });
            promise.then(
                result => {
                    switch (result.f) {
                        case true:
                            makeToast(that, 'danger', getSmsTitle(this, 'warning'),
                                getSmsTitle(this, 'file_exists_rename_file'));
                            break;
                        case false:
                            that.files.push(result.newItem);
                            that.getFiles();
                            makeToast(that, 'success', getSmsTitle(this, 'message'),
                                getSmsTitle(this, 'file_uploaded_success'));
                            break;
                        case 'error':
                            makeToast(that, 'danger', getSmsTitle(this, 'error_download'), '');
                            break;
                        default:
                            break;
                    }
                    that.$refs['file-input'].reset();
                }, error => {
                    makeToast(that, 'danger', getSmsTitle(this, 'warning'), error.toString());
                }
            );
        }, // закачать файл

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getCardTitle(sector, field) {
            return getCardTitle(this, sector, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>