








































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Component, Vue, Prop} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import {
    makeToast,
    findItem,
    IAbp,
    getBipTitle, nameLocale, getSmsTitle
} from './bip-types';

@Component({
    name: 'budget-header-bip',
    components: {
        'multiselect': Multiselect
    }
})
export default class BudgetHeaderBip extends Vue {
    @Prop({
        required: true,
        default: true
    })
    private reading!: boolean;

    @Prop({
        required: false,
        default: undefined
    })
    private form: any | undefined;

    @Prop({
        required: false,
        default: null
    })
    private variant: any | null;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private abpBase: any[] | undefined;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private regAbpBase: any[] | undefined;

    public isSetValueState = false;
    private finalAbp: number = 1;
    private guBase: any[] = [];
    private regGuBase: any[] = [];
    private selAbp: IAbp | null = null;
    private selGu: any | null = null
    private regAbp: IAbp | null = null;
    private regGu: any | null = null;

    public async created() {
        this.$watch('form', (value) => {
            if (value) {
                this.setValues(value.header);
            }
        });
        this.$watch('finalAbp', () => {
            this.msgRegional();
        });
        this.$watch('selGu', (value) => {
            this.changeHeader();
        });
        this.$watch('regGu', (value) => {
            this.changeHeader();
        });
    }

    // ------------------ администратор программ ------------------
    private get abpList(): any[] {
        if (this.abpBase) {
            return (this.regAbp === null ? this.abpBase :
                this.abpBase.filter(row => (this.regAbp !== null && row.abp !== this.regAbp.abp)));
        } else {
            return [];
        }
    }

    private get guList(): any[] {
        if (this.selAbp === null) {
            return [];
        } else {
            this.guBase.sort((a, b) => (a.code > b.code) ? 1 : -1);
            return this.guBase;
        }
    }

    // ------------------ рег.администратор программ --------
    private get regAbpList(): any[] {
        let result = [];
        if (this.selAbp && this.regAbpBase) {
            result = this.regAbpBase.filter(row => (this.selAbp !== null && row.abp !== this.selAbp.abp));

            if (this.selAbp.budget_level_id === 2) {
                result = result.filter(row => row.budget_level_id === 3);
            }
            if (this.selAbp.budget_level_id === 3) {
                result = result.filter(row => row.budget_level_id === 4);
            }
        }
        return result;
    }

    private get regGuList(): any[] {
        if (this.regAbp === null) {
            return [];
        } else {
            this.regGuBase.sort((a, b) => (a.code > b.code) ? 1 : -1);
            if (this.selGu?.id_budget_type === '03') {
                return this.regGuBase.filter(row => this.selGu
                    && row.id_region.substr(0, 4) === this.selGu.id_region.toString().substr(0, 4));
            }
            return this.regGuBase;
        }
    }

    private async chgAbp() {
        if (this.selAbp !== null) {
            this.selGu = null;
            await this.loadGu();
        }
        if ((this.form.header?.abp !== this.selAbp?.abp) && (this.form.newFinace.length > 0)) {
            makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                getSmsTitle(this, 'ABP_changed_resel_prg_ppr_section4'));
        }
    }

    private changeHeader() {
        if (this.finalAbp === 0 && this.regGuList.notIncludes(this.regGu)) {
            this.regGu = null;
        }
        const data = {
            selAbp: this.selAbp,
            selGu: this.selGu,
            finalAbp: this.finalAbp,
            regAbp: this.regAbp,
            regGu: this.regGu,
            edited: false,
            regEdited: false
        }

        this.$set(data, 'edited', this.form.header?.abp !== this.selAbp?.abp);
        this.$set(data, 'regEdited', false);
        if (this.finalAbp === 0) {
            this.$set(data, 'regEdited', this.form.header.regional && (this.form.header.regional.abp !== this.regAbp?.abp));
        }
        this.isSetValueState = (data.edited || data.regEdited);

        this.$emit('changeHeader', data);
    }

    private async chgRegAbp() {
        if (this.regAbp !== null) {
            this.regGu = null;
            await this.loadRegGu();
        }
        if ((this.form.header?.regional?.abp !== this.regAbp?.abp) && (this.form.newFinaceRegional.length > 0)) {
            makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                getSmsTitle(this, 'ABP_changed_resel_prg_ppr_section4'));
        }
    }

    private msgRegional() {
        if (this.isSetValueState && this.finalAbp === 1) {
            if (this.regAbp && this.regGu) {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'recipients_ABP_be_deleted_change_attr_ok') + '?',
                    {
                  title: getSmsTitle(this, 'confirmation'),
                  size: 'lg',
                  buttonSize: 'sm',
                  okVariant: 'danger',
                  okTitle: getBipTitle(this, 'btn.ok'),
                  cancelTitle: getBipTitle(this, 'btn.cancel'),
                  footerClass: 'p-2',
                  hideHeaderClose: false,
                  centered: true
                }).then(value => {
                  if (value) {
                    this.regAbp = null;
                    this.regGu = null;
                  } else {
                    this.finalAbp = 0;
                  }
                  this.changeHeader();
                }).catch(error => {
                  makeToast(this, 'danger', getSmsTitle(this, 'error_save'), error.toString());
                });
            }
        }

        if (this.isSetValueState && this.finalAbp === 0) {
            if ((this.form.newFinace.length > 0) || (this.form.newFinaceRegional.length > 0)) {
                makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'need_del_datas_from_sec_4'));
                this.finalAbp = 1;
                return;
            }
        }
    }

    // --------- гос.учреждение ------------
    private async loadGu() {
        if (this.selAbp) {
            try {
                const result = await fetch(`/api-py/dict-gu-by-reg-abp/${this.selAbp.abp}/${this.variant.variant_uuid}/`)
                    .then(response => response.json());
                if (result) {
                    nameLocale(result)
                    this.guBase = result;
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_load') + ' /dict-gu-by-reg-abp');
            }
        }
    }

    // --------- рег.гос.учреждение ------------
    private async loadRegGu() {
        this.regGu = null;
        if (this.regAbp) {
            try {
                const result = await fetch(`/api-py/dict-gu-by-reg-abp/${this.regAbp.abp}/${this.variant.variant_uuid}/`)
                    .then(response => response.json());
                if (result) {
                    nameLocale(result);
                    this.regGuBase = result;
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_load') + ' /dict-gu-by-reg-abp');
            }
        }
    }

    // header -> текущие значения
    public async setValues(data: any) {
        this.selAbp = null;
        this.selGu = null;
        this.regAbp = null;
        this.regGu = null;
        if (data) {
            this.$set(this, "finalAbp", data.finalAbp);
            if (data.abp) {
                this.selAbp = await findItem(data?.abp, 'abp', this.abpList);
                await this.chgAbp();
            }
            if (data.gu) {
                this.selGu = await findItem(data?.gu?.code, 'code', this.guList);
            }
            if (data.regional && data.regional.abp) {
                this.regAbp = findItem(data?.regional.abp, 'abp', this.regAbpList);
                await this.chgRegAbp();

                if (data?.regional.gu) {
                    this.regGu = await findItem(data?.regional.gu.code, 'code', this.regGuList); ;
            }
            }
            this.changeHeader();
        }
        this.isSetValueState = false;
        console.log('setValues', data);
    }

    private getCardTitle(sector: string, field: string) {
        return getBipTitle(this, 'card.' + sector + '.' + field);
    }

    private getSmsTitle(field: string) {
        return getSmsTitle(this, field);
    }

    private getBipTitle(field: string) {
        return getBipTitle(this, field);
    }
}
